import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = (props) => {

  const data =useStaticQuery(graphql`
      query GetFeatureImage{
      wpgraphql {
        harbours(where: {title: "Jersey Harbours"}) {
          nodes {
            AllPostTypeFields {
              bannerlink
            }
            featuredImage {
              node
              {
              mediaItemUrl
              altText
              }
            }
          }
        }
        ports(where: {title: "Ports of Jersey"}) {
          nodes {
            AllPostTypeFields {
              bannerlink
            }
            featuredImage {
              node
              {
              mediaItemUrl
              altText
              }
            }
          }
        }
        coastguards(where: {title: "Jersey Coastguard"}) {
          nodes {
            AllPostTypeFields {
              bannerlink
            }
            featuredImage {
              node
              {
              mediaItemUrl
              altText
              }
            }
          }
      }
      marinas(where: {title: "Jersey Marinas"}) {
        nodes {
          AllPostTypeFields {
            bannerlink
          }
          featuredImage {
            node
            {
            mediaItemUrl
            altText
            }
          }
        }
      }
      airports(where: {title: "Jersey Airport"}) {
        nodes {
          AllPostTypeFields {
            bannerlink
          }
          featuredImage {
            node
            {
            mediaItemUrl
            altText
            }
          }
        }
      }
         }
  }
      `);
   
    if(props.scr.length!==0)
    {
      if (props.scr[0].link != null) {
        return (<a href={props.scr[0].link}><img src={props.scr[0].url} alt={props.scr[0].title} /></a>)
      }
      else {
        return (<img src={props.scr[0].url} alt={props.scr[0].title} />)
      }
    }
    else
    {
      if(props.Mode==="airport")
      {

          if(data.wpgraphql.airports.nodes.length>0)
            if(data.wpgraphql.airports.nodes[0].AllPostTypeFields.bannerlink != null)
              return <a href={data.wpgraphql.airports.nodes[0].AllPostTypeFields.bannerlink}><img src={data.wpgraphql.airports.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.airports.nodes[0].featuredImage.node.altText}  /></a>
            else
              return <img src={data.wpgraphql.airports.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.airports.nodes[0].featuredImage.node.altText}  />
          else{
          if(data.wpgraphql.ports.nodes[0].AllPostTypeFields.bannerlink != null)
          
          return <a href={data.wpgraphql.ports.nodes[0].AllPostTypeFields.bannerlink}><img src={data.wpgraphql.ports.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.ports.nodes[0].featuredImage.node.altText} /></a>
          
        else
          return <img src={data.wpgraphql.ports.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.ports.nodes[0].featuredImage.node.altText} />
      }
      }
      if(props.Mode==="marina")
      {

          if(data.wpgraphql.marinas.nodes.length>0)
            if(data.wpgraphql.marinas.nodes[0].AllPostTypeFields.bannerlink != null)
              return <a href={data.wpgraphql.marinas.nodes[0].AllPostTypeFields.bannerlink}><img src={data.wpgraphql.marinas.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.marinas.nodes[0].featuredImage.node.altText}  /></a>
            else
              return <img src={data.wpgraphql.marinas.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.marinas.nodes[0].featuredImage.node.altText}  />
          else
            return <img src={data.wpgraphql.ports.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.ports.nodes[0].featuredImage.node.altText} />
      }
      if(props.Mode==="harbour")
      {

          if(data.wpgraphql.harbours.nodes.length>0)
            if(data.wpgraphql.harbours.nodes[0].AllPostTypeFields.bannerlink != null)
              return <a href={data.wpgraphql.harbours.nodes[0].AllPostTypeFields.bannerlink}><img src={data.wpgraphql.harbours.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.harbours.nodes[0].featuredImage.node.altText}  /></a>
            else
              return <img src={data.wpgraphql.harbours.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.harbours.nodes[0].featuredImage.node.altText}  />
          else
           return <img src={data.wpgraphql.ports.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.ports.nodes[0].featuredImage.node.altText} />
      }
      if(props.Mode==="coastguard")
      {

          if(data.wpgraphql.coastguards.nodes.length>0)
            if(data.wpgraphql.coastguards.nodes[0].AllPostTypeFields.bannerlink != null)
              return <a href={data.wpgraphql.coastguards.nodes[0].AllPostTypeFields.bannerlink}><img src={data.wpgraphql.coastguards.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.coastguards.nodes[0].featuredImage.node.altText}  /></a>
            else
            return <img src={data.wpgraphql.coastguards.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.coastguards.nodes[0].featuredImage.node.altText}  />
          else
           return <img src={data.wpgraphql.ports.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.ports.nodes[0].featuredImage.node.altText} />
      }
      if(props.Mode==="port")
      {
        if(data.wpgraphql.ports.nodes[0].AllPostTypeFields.bannerlink != null)
          
          return <a href={data.wpgraphql.ports.nodes[0].AllPostTypeFields.bannerlink}><img src={data.wpgraphql.ports.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.ports.nodes[0].featuredImage.node.altText} /></a>
          
        else
          return <img src={data.wpgraphql.ports.nodes[0].featuredImage.node.mediaItemUrl} alt={data.wpgraphql.ports.nodes[0].featuredImage.node.altText} />
      }
      return(<img src='https://cdn.ports.je/web/PortsBanner.jpg' alt='' />);
    }
    
  }


export default Image
